<script>
import DownFile from "../../../request/expand/downFile";

export default {
  name: "down_service_down",
  data() {
    return {
      ask: new DownFile(),
      search: "",
      ULHeight: (window.innerHeight - 16 * 2 - 105 - 40 - 40),
      controller: null,
      activeName: 'third',
      downLoadingData: [],
      downLoadingPage: {
        "pageNum": 1,
        "pageSize": 3,
        "state": 1,
        "total": 0
      },
      allData: [],
      allDataPage: {
        "pageNum": 1,
        "pageSize": 10,
        "state": -1,
        "title": ""
      },
      addForm: {
        "imgUrl": "",
        "fileName": "",
        "m3u8Url": "",
        "threadCount": 10,
        "convertIf": false,
        "isProxy": false,
        "total": 0,
        "tagIds": "",
        "downWay": 1
      },
      currentTab: "first",
      options: [],
      value: [],
      currentPage: 1,
      wayList: [
        {"name": "cron", "value": 0},
        {"name": "N_m3u8DL", "value": 1}
      ],
      query: {
        "title": "",
        "tagIds": [],
        "pageNum": 1,
        "pageSize": 9,
      },
    }
  },
  mounted() {
    this.ask.host = this.$storageHandler.getSessionStorage("nas_host");
    if (!this.ask.host) {
      this.ask.downGetHost().then(res => {
        this.ask.host = `https://charonv.${res.data.relayDomain}/down`
        this.$storageHandler.setSessionStorage("nas_host", this.ask.host);
        this.getTag()
      })
    } else {
      this.getTag()
    }
    this.addForm.m3u8Url = this.$route.params.link
    this.addForm.imgUrl = this.$route.params.img
    this.addForm.fileName = this.$route.params.title
    // const paramsStr = window.location.search
    // const params = new URLSearchParams(paramsStr)
    // if (params.get("link")) {
    //   this.addForm.m3u8Url = unescape(params.get("link"))
    // }
    // if (params.get("img")) {
    //   this.addForm.imgUrl = unescape(params.get("img"))
    // }
    // if (params.get("title")) {
    //   this.addForm.fileName = unescape(params.get("title"))
    // }

  },
  methods: {
    getTag() {
      this.ask.downGetTagData(null).then(res => {
        this.options = res
      })
    },
    remoteMethod(query) {
      this.ask.downGetTagDataByName({"name": query}).then((res) => {
        this.options = res
      })
    },
    terminatedTask(row) {
      this.ask.downTerminatedTask({"filePath": row.filePath}).then((res) => {
        this.getAllData(this.currentPage)
      })
    },
    getAllData(pageNum) {
      this.allDataPage.pageNum = pageNum
      this.ask.downGetDataS(this.allDataPage).then(res => {
        this.allDataPage.total = res.total
        this.allData = res.list
      })
    },
    changeState(row, state) {
      this.ask.downChangeTaskState({
        "filePath": row.filePath,
        "state": state
      }).then(res => {
        this.getAllData(this.currentPage)
      })
    },
    deleteTask(row) {
      if (row.state === 4) {
        this.ask.downDelRes({"id": row.id}).then(res => {
          this.getAllData(this.currentPage)
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success'
          });
        })
      }
      this.ask.downDelTask({
        "filePath": row.filePath
      }).then(res => {
        this.getAllData(this.currentPage)
        this.$message({
          showClose: true,
          message: '删除成功!',
          type: 'success'
        });
      })
    },
    play(id, type) {
      if (type === 'm3u8') {
        const host = this.ask.host + "/downFile/" + id + "/index.m3u8"
        const url = `https://charonv.com/player?url=${host}`
        window.open(url, "_blank");
      } else if (type === 'mp4') {
        const host = this.ask.host + "/downFile/" + id + "/" + id + ".mp4"
        const url = `/player?url=${host}`
        window.open(url, "_blank");
      }
    },
    handleCurrentChange(val) {
      if (this.currentTab === 'first') {
        this.stopSSE()
        this.downLoadingPage.pageNum = val
        this.ask.downGetDataS(this.downLoadingPage).then(res => {
          if (res.list.length !== 0) {
            this.downLoadingPage.total = res.total
            res.list.forEach(item => {
              item['rate'] = '000';
              item['plan'] = 0
            })
            this.downLoadingData = res.list
            let newList = res.list.map(item => ({path: item.filePath, size: item.size}));
            this.fetchAsk(newList);
          } else {
            this.downLoadingPage.tota = res.total
            this.downLoadingData = res.list
          }
        })
      } else if (this.currentTab === 'second') {
        this.getAllData(val)
      }
    },
    onSubmit() {
      this.addForm.m3u8Url = this.addForm.m3u8Url.trim();
      this.addForm.imgUrl = this.addForm.imgUrl == null ? null : this.addForm.imgUrl.trim();
      this.addForm.tagIds = this.value.toString()
      this.ask.downAddTask(this.addForm).then(res => {
        if (res.code === 'success') {
          this.addForm.tagIds = []
          this.addForm.fileName = ''
          this.addForm.m3u8Url = ''
          this.addForm.imgUrl = ''
          this.$message({
            showClose: true,
            message: '恭喜你，新增成功',
            type: 'success'
          });
        }
      })
    },
    handleClick(tab, event) {
      this.currentTab = tab['_props']['name']
      if (tab['_props']['name'] === 'second') {
        this.getAllData(this.currentPage)
        this.stopSSE();
      } else if (tab['_props']['name'] === 'first') {
        this.ask.downGetDataS(this.downLoadingPage).then(res => {
          if (res.list.length !== 0) {
            this.downLoadingPage.total = res.total
            res.list.forEach(item => {
              item['rate'] = '000';
              item['plan'] = 0
            })
            this.downLoadingData = res.list
            let newList = res.list.map(item => ({path: item.filePath, size: item.size}));
            this.fetchAsk(newList);
          } else {
            this.downLoadingPage.tota = res.total
            this.downLoadingData = res.list
          }
        })
      } else {
        this.stopSSE()
      }
    },
    fetchAsk(newList) {
      let controller = new AbortController();
      let signal = controller.signal;
      this.controller = controller
      fetch(this.ask.host + '/file/readDownPlan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newList),
        signal: signal  // 将信号对象添加到fetch请求中
      })
          .then(response => {
            // 检查是否收到成功响应
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // 处理 SSE
            this.handleSSE(response);
          })
          .catch(error => {
            console.error('请求错误:', error);
          });
    },
    handleSSE(response) {
      let this_ = this;
      const reader = response.body.getReader();
      let buffer = '';

      // 处理每个数据块的函数
      function processStream({done, value}) {
        if (done) {
          console.log('连接已关闭');
          return;
        }
        const chunk = new TextDecoder('utf-8').decode(value);
        buffer += chunk;
        // 处理分隔的消息
        while (buffer.includes('\n\n')) {
          const separatorIndex = buffer.indexOf('\n\n');
          const message = buffer.substring(0, separatorIndex);
          buffer = buffer.substring(separatorIndex + 2);
          const data = message.replaceAll("\n", "").replaceAll("\t", "").replaceAll("data:", "")
          let obj = JSON.parse(data)
          let index = this_.downLoadingData.findIndex(element => element['filePath'] === obj['taskId']);
          if (index !== -1) {
            this_.downLoadingData[index]['rate'] = obj.rate;
            this_.downLoadingData[index]['plan'] = obj.plan;
          }
        }
        // 继续读取下一个结果
        return reader.read().then(processStream);
      }

      // 读取第一个结果
      reader.read().then(processStream).catch(error => {
        console.error('读取错误:', error);
      });
    },
    stopSSE() {
      try {
        this.controller.abort();
      } catch (e) {
        console.log("大橙同学")
      }
    }
  }
}
</script>

<template>
  <div id="app">
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
        <el-tab-pane label="下载中" name="first">
          <el-table
              :data="downLoadingData"
              :height="ULHeight"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="fileName"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="filePath"
                label="任务标识">
            </el-table-column>
            <el-table-column
                prop="size"
                label="大小">
            </el-table-column>
            <el-table-column
                label="状态">
              <template slot-scope="scope">
                <el-tag type="warning">{{ scope.row.state === 1 ? "下载中" : "" }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="rate"
                label="速率">
            </el-table-column>
            <el-table-column
                prop="plan"
                label="进度">
            </el-table-column>
            <el-table-column
                prop="creationTime"
                label="创建时间">
            </el-table-column>
          </el-table>
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :pager-count="5"
              :page-size="downLoadingPage.pageSize"
              layout="prev, pager, next"
              :total="downLoadingPage.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="添加任务" name="third">
          <el-form ref="form" :model="addForm">
            <el-form-item label="名称">
              <el-input v-model="addForm.fileName"></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="addForm.m3u8Url" placeholder="请输入网络地址">
                <template slot="prepend">https://</template>
              </el-input>
            </el-form-item>
            <el-form-item label="封面">
              <el-input placeholder="请输入网络地址" v-model="addForm.imgUrl">
                <template slot="prepend">https://</template>
              </el-input>
            </el-form-item>
            <el-form-item label="并发">
              <el-input type="number" v-model="addForm.threadCount"></el-input>
            </el-form-item>
            <el-form-item label="标签" class="text-right">
              <el-select
                  v-model="value"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod">
                <el-option
                    v-for="item in options"
                    :key="item.tagId"
                    :label="item.tagName"
                    :value="item.tagId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="代理">
              <el-radio-group v-model="addForm.isProxy" size="medium">
                <el-radio border :label="true">是</el-radio>
                <el-radio border :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="下载方式">
              <el-radio-group v-model="addForm.downWay" size="medium">
                <el-radio border v-for="(iter, index) in wayList" :key="index" :label="iter.value">
                  {{ iter.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="转格式">
              <el-radio-group v-model="addForm.convertIf" size="medium">
                <el-radio border :label="true">转MP4</el-radio>
                <el-radio border :label="false">无操作</el-radio>
              </el-radio-group>

            </el-form-item>
          </el-form>
          <p style="text-align: center">
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
          </p>
        </el-tab-pane>
        <el-tab-pane label="全部任务" name="second">
          <div>
            <el-input placeholder="请输入内容" v-model="allDataPage.title" class="input-with-select">
              <el-select v-model="query.tagIds" multiple placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.tagId"
                    :label="item.tagName"
                    :value="item.tagId">
                </el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="getAllData(1)"></el-button>
            </el-input>
          </div>
          <el-table
              :data="allData" border
              :height="ULHeight"
              style="width: 100%">
            <el-table-column
                fixed
                prop="fileName"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="filePath"
                label="任务标识"></el-table-column>
            <el-table-column
                prop="size"
                label="大小">
            </el-table-column>
            <el-table-column label="转换" width="60">
              <template slot-scope="scope">
                <el-tag size="mini" type="warning" v-if=" scope.row.convertIf == true">已转换
                </el-tag>
                <el-tag size="mini" type="success" v-else>未转换</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="代理" width="60">
              <template slot-scope="scope">
                <el-tag size="mini" type="warning" v-if=" scope.row.isProxy == true">是
                </el-tag>
                <el-tag size="mini" type="success" v-else>否</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="下载方式">
              <template slot-scope="scope">
                <el-tag size="mini" type="warning" v-if=" scope.row.downWay == 1">N_m3u8DL
                </el-tag>
                <el-tag size="mini" type="success" v-else>cron</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                label="状态">
              <template slot-scope="scope">
                <el-tag type="warning" v-if=" scope.row.state === 1">下载中</el-tag>
                <el-tag type="success" v-else-if="scope.row.state === 2">已完成</el-tag>
                <el-tag type="danger" v-else-if=" scope.row.state === 3">异常</el-tag>
                <el-tag type="danger" v-else-if=" scope.row.state === 4">软删除</el-tag>
                <el-tag type="primary" v-else-if=" scope.row.state === 0">等待中</el-tag>
                <el-tag type="info" v-else-if=" scope.row.state === 5">暂停</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="creationTime"
                label="创建时间">
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                    size="mini" :disabled="scope.row.state === 1"
                    type="danger"
                    @click="deleteTask(scope.row)">删除
                </el-button>
                <el-button
                    size="mini"
                    type="danger" v-if="scope.row.state === 1"
                    @click="terminatedTask(scope.row)">终止
                </el-button>
                <el-button
                    v-if="scope.row.state === 2"
                    size="mini"
                    @click="play(scope.row.filePath, scope.row.convertIf == true ? 'mp4' : 'm3u8')">播放
                </el-button>
                <el-button
                    size="mini" v-if="scope.row.state === 0"
                    type="info"
                    @click="changeState(scope.row, 5)">暂停
                </el-button>
                <el-button
                    size="mini" v-if="scope.row.state === 3 || scope.row.state === 5 || scope.row.state === 4"
                    type="primary"
                    @click="changeState(scope.row, scope.row.state === 4 ? 2 : 0)">恢复
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :pager-count="5"
              :page-size="allDataPage.pageSize"
              layout="prev, pager, next"
              :total="allDataPage.total">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style scoped>
.el-pagination {
  margin-top: 5px;
  text-align: center;
}
</style>